<template>
  <div>
    <v-card>
      <v-card-title class="text-h6"
        >{{ item.tracking_id }} - {{ $t("labels.quantity") }}:
        {{ item.quantity }}</v-card-title
      >
      <v-card-text>{{ $t("messages.update_quantity") }}</v-card-text>

      <v-card-text>
        <v-text-field
          v-model.number="quantity"
          class="c-input-xs mb-3"
          @keyup.enter="confirm"
          type="number"
          :label="$t('labels.quantity')"
          autofocus
          dense
          outlined
          clearable
          hide-details
        ></v-text-field>
        <v-textarea
          v-model="reason"
          class="c-input-xs mb-3"
          @keyup.enter="confirm"
          :label="$t('labels.update_reason')"
          dense
          outlined
          clearable
          hide-details
        ></v-textarea>
        <div class="error--text">
          *{{ $t("messages.update_quantity_note") }}
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="cancel">
          {{ $t("labels.cancel") }}
        </v-btn>
        <v-btn
          color="green darken-1"
          text
          @click="confirm"
          v-if="quantity < item.quantity && reason"
        >
          {{ $t("labels.confirm") }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog v-model="rollDialog" persistent max-width="350px">
      <UpdateQuantityRollUid
        v-if="rollDialog"
        :item="item"
        :roll-ids="rollIds"
        :quantity="quantity"
        @confirm="confirmRoll"
        @cancel="cancelRoll"
      />
    </v-dialog>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import moment from "moment/moment";

export default {
  name: "UpdateQuantity",
  components: {
    UpdateQuantityRollUid: () =>
      import("@/components/goods_receipt/UpdateQuantityRollUid"),
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    isLoading: false,
    quantity: null,
    reason: null,
    rollDialog: false,
    rollIds: [],
  }),
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    cancelRoll() {
      this.rollDialog = false;
    },
    confirmRoll(rollIds) {
      this.rollIds = [...rollIds];
    },
    confirm() {
      const needRollUid = this.item.quantity - this.quantity;
      const startNewStampDate = moment("2022-09-27 00:00:00").unix();
      const handleDate = moment(this.item.handle_time).unix();
      if (
        this.quantity > 0 &&
        needRollUid !== this.rollIds.length &&
        !this.item.no_label &&
        handleDate > startNewStampDate
      ) {
        this.rollDialog = true;
        return false;
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      if (this.quantity < this.item.quantity && this.reason) {
        try {
          httpClient.post("/goods-receipt/v1/update-quantity", {
            id: this.item.id,
            quantity: this.quantity,
            rollIds: this.rollIds,
            reason: this.reason,
          });
          this.isLoading = false;
          document.getElementById("success_sound_player").play();
          this.cancel();
          this.$emit("refreshData");
        } catch (e) {
          const errMsg =
            (e.response &&
              e.response.data &&
              e.response.data.error &&
              e.response.data.error.message) ||
            null;
          this.$vToastify.error(errMsg);
          this.isLoading = false;
          document.getElementById("error_sound_player").play();
        }
      } else {
        this.isLoading = false;
        this.$vToastify.error(this.$t("messages.no_quantity_reason"));
        document.getElementById("error_sound_player").play();
      }
    },
  },
};
</script>

<style scoped></style>
